
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'

@Component({
  components: { RowData, RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class PhotosSupervisorPanel extends BaseStepper {
  componentKeys = []
  componentKeys2 = []
  fileParameters = []
  validation = false
  ready = false
  formData: Record<string, any> = {}

  async mounted () {
    this.fileParameters = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    const sortedFileParameters = this.fileParameters.sort((a, b) => a.id - b.id)
    this.componentKeys = sortedFileParameters.filter(parameter => parameter.id >= 16 && parameter.id <= 24).map(fp => fp.description)
    this.componentKeys2 = sortedFileParameters.filter(parameter => parameter.id >= 25 && parameter.id <= 28).map(fp => fp.description)

    this.generateFormInfo(this.fileParameters)
  }

  generateFormInfo (components) {
    for (const field of components) {
      this.$set(this.formData, field.description, {
        photo: [],
      })
    }
    this.ready = true
  }

  async send () {
    const { categoryName, category } = this
    this.$emit('input', { categoryName, category })
    this.$emit('inspection-category-done')
  }

  get readyView () {
    const { ready, selfPanel } = this

    return {
      ready,
      selfPanel,
    }
  }

  @Watch('readyView', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { fileParameters, inspection } = this

    if (!val.selfPanel) {
      return
    }

    const handleComponentChange = async (parameter, key) => {
      this.formData[key].photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: inspection.id } },
            { id_file_parameter: { _eq: parameter.id } },
          ],
        },
        force: true,
      })
    }

    for (const parameter of fileParameters) {
      await handleComponentChange(parameter, parameter.description)
    }
  }

  get viewStep () {
    const { step, componentKeys, componentKeys2 } = this

    return {
      step,
      componentKeys,
      componentKeys2,
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }
  }
